
    
    import {gmapApi} from 'vue2-google-maps';



    import * as geolib from 'geolib';
    
    
    const stringOptions = ([
      { text: 'BASALA', value: 'BASALA' }, { text: 'BENUA', value: 'BENUA' }, { text: 'BUKE', value: 'BUKE' }, { text: 'KONDA', value: 'KONDA' }, { text: 'LAEYA', value: 'LAEYA' }, { text: 'LALEMBUU', value: 'LALEMBUU' }, { text: 'LANDONO', value: 'LANDONO' }, { text: 'PALANGGA', value: 'PALANGGA' },   { text: 'MOWILA', value: 'MOWILA' },
      { text: 'KOLONO TIMUR', value: 'KOLONO TIMUR' },
      { text: 'SABULAKOA', value: 'SABULAKOA' },
      { text: 'ANDOOLO BARAT', value: 'ANDOOLO BARAT' },
      { text: 'ANDOOLO', value: 'ANDOOLO' },
      { text: 'ANGATA', value: 'ANGATA' },
      { text: 'BAITO', value: 'BAITO' },
      { text: 'PALANGGA SELATAN', value: 'PALANGGA SELATAN' },
      { text: 'RANOMEETO', value: 'RANOMEETO' },
      { text: 'RANOMEETO BARAT', value: 'RANOMEETO BARAT' },
      { text: 'WOLASI', value: 'WOLASI' },
      { text: 'KOLONO', value: 'KOLONO' },
      { text: 'LAINEA', value: 'LAINEA' },
      { text: 'LAONTI', value: 'LAONTI' },
      { text: 'MORAMO', value: 'MORAMO' },
      { text: 'MORAMO UTARA', value: 'MORAMO UTARA' },
      { text: 'TINANGGEA', value: 'TINANGGEA' },
      
    ])
    
    
    
    export default {
      data() {
        return {
        
        markerInsideIndex: -1, // Indeks polygon tempat titik berada


        mdl_lihat_titik: false,

        clusterOptions: {
            gridSize: 30,
            maxZoom: 15,
        },

        all_polygons_zona: [],
        polygons_zona: [],

//////////////////////////ZONA//////////////////////////////
    //     polygons_zona: [
    //     {
    //       paths: [
    //         { lat: -4.333731, lng: 122.274770 },
    //         { lat: -4.328698, lng: 122.279753 },
    //         { lat: -4.339085, lng: 122.293835 },
    //         { lat: -4.345920, lng: 122.285279 },
    //         // Koordinat vertex poligon pertama
    //       ],
    //     },
    //     {
    //       paths: [
    //         { lat: -4.356667, lng: 122.279725 },
    //         { lat: -4.353998, lng: 122.266840 },
    //         { lat: -4.342981, lng: 122.268637 },
    //         { lat: -4.346094, lng: 122.287058 },
    //         // Koordinat vertex poligon kedua
    //       ],
    //     },
    
    //     },
    //     // Tambahkan poligon tambahan di sini
    //   ],
        

      polygonZonaOptions: {
        fillColor: 'red',
        fillOpacity: 0.5,
        // Opsi warna dan kecerahan arsiran polygon
      },

      markers_zona: [],
    

///////////////////////END ZONA/////////////////////////////////

///////////////////////TITIK/////////////////////////////////

      polygons_titik: [],
    
      polygonTitikOptions: {
        fillColor: 'blue',
        fillOpacity: 0.5,
        // Opsi warna dan kecerahan arsiran polygon
      },

      markers_titik: [] ,
    

    markerIcon: {
        url: 'img/a.png',
        scaledSize: { width: 40, height: 40 },
      },

    
      ///////////////////////////END TITIK///////////////////////////// 
    
      
    
          form : {
            kecamatan: '',
          },
          lokasi: [],

          nilai:null,
          //
         
          no: 0,
          id: '',
          url: {
            GET_URL: this.$store.state.url.URL_APP + "api/v1/web_zona/"
          },
          list_data: [],
          viewTitik: [],
    
          
          cari_value: "",
          options: stringOptions,

         


          titik : {
                id: '',
                subject_pajak_id: '',
                nik: '',
                nama: '',
                alamat: '',
                kelurahan: '',
                kecamatan: '',
                rwrt: '',
                no_telp: '',
                lokasi: '',
                file: '',
            },

          file_old: "",
          file_path: this.$store.state.url.URL_APP + "uploads/",


        }
      },
      methods: {

        
      
        isMarkerInsidePolygon: function(titik_lokasi){

            titik_lokasi.latitude = parseFloat(titik_lokasi.lat);
            titik_lokasi.longitude = parseFloat(titik_lokasi.lng);

            for (let i = 0; i < this.all_polygons_zona.length; i++) {
                if (geolib.isPointInPolygon(titik_lokasi, this.all_polygons_zona[i])) {

                    this.markerInsideIndex = i;

                    return this.markerInsideIndex;
                }
            }
            return false;
            
        },
        

        handlePolygonClick: function(index_marker){

            var nilai_zona_tanah = this.polygons_zona[index_marker].ambil_nilai.nilai;
            var zona = this.polygons_zona[index_marker].ambil_nilai.zona;
            var kecamatan = this.polygons_zona[index_marker].ambil_nilai.kecamatan;

            this.Notify(`Zona ini termasuk kedalam Zona ${zona} pada Kecamatan ${kecamatan} dengan Zona Nilai Tanah yaitu Rp.${nilai_zona_tanah}`);
           
            // alert("HAIII "+ this.titik.nama + " dan memiliki harga zona sebesar Rp. "+  nilai_zona_tanah );
        },

        petunjuk: function(index_marker){

          
            const jsonData = this.markers_titik[index_marker].lokasi[index_marker];

            var index_zona = this.isMarkerInsidePolygon(jsonData);
            
            console.log("index zona nya : ", index_zona);


            var nilai_zona_tanah = this.polygons_zona[index_zona].ambil_nilai.nilai
           
            // alert("Hello harga tanah di sini adalah "+ this.titik.nama + " dan memiliki harga zona sebesar Rp. "+ nilai_zona_tanah  );
        },

        getView: function() {
          this.cek_load_data = true;


          fetch(this.url.GET_URL + "view", {
              method: "POST",
              headers: {
                "content-type": "application/json",
                authorization: "kikensbatara " + localStorage.token
              },
              body: JSON.stringify({
                cari_value: this.form.kecamatan
              })
            })
            .then(res => res.json())
            .then(res_data => {
    
              
              this.list_data = res_data;
             
              this.cek_load_data = false;


                //////////////CONVERT TO FLOAT AND PUSH TO ALL ZONES ///////////////
                
               

                for (let i = 0; i < this.list_data.length; i++) {
                    const floatArray = res_data[i].lokasi.map(obj => {
                        return {
                            latitude: parseFloat(obj.lat),
                            longitude: parseFloat(obj.lng),
                        };
                        // return {
                        //     lat: parseFloat(obj.lat),
                        //     lng: parseFloat(obj.lng),
                        // };
                        
                    });
                   
                    
                    this.all_polygons_zona.push(floatArray);
                }

                // console.log("all zona : ", this.all_polygons_zona);

                  
                //////////////CONVERT TO FLOAT AND PUSH TO ARRAY POLYGON///////////////
                // console.log("ambil nilai : ", this.list_data);

                for (let i = 0; i < this.list_data.length; i++) {
                    const floatArray = res_data[i].lokasi.map(obj => {
                        return {
                            lat: parseFloat(obj.lat),
                            lng: parseFloat(obj.lng),
                        };
                    });
                   
                    let obj = {
                        paths : floatArray,
                        ambil_nilai : this.list_data[i]
                    };

                    this.polygons_zona.push(obj);

                }

               

                console.log(this.polygons_zona);

                //////////////CONVERT TO FLOAT AND PUSH TO MARKERS///////////////

                this.markers_zona = res_data;

                // console.log(this.markers_zona);

    
            });
        },
    

        getViewTitik: function() {
          this.cek_load_data = true;


          fetch(this.url.GET_URL + "viewTitik", {
              method: "POST",
              headers: {
                "content-type": "application/json",
                authorization: "kikensbatara " + localStorage.token
              },
              body: JSON.stringify({
                cari_value: this.form.kecamatan
              })
            })
            .then(res => res.json())
            .then(res_data => {
    
              
              this.viewTitik = res_data;
             
              this.cek_load_data = false;
              

                //////////////CONVERT TO FLOAT AND PUSH TO ARRAY///////////////

                for (let i = 0; i < this.viewTitik.length; i++) {
                    const floatArray = res_data[i].lokasi.map(obj => {
                        return {
                            lat: parseFloat(obj.lat),
                            lng: parseFloat(obj.lng),
                        };
                    });
                   
                    let obj = {
                        paths : floatArray
                    };

                    this.polygons_titik.push(obj);
                }

                //////////////CONVERT TO FLOAT AND PUSH TO MARKERS///////////////

                this.markers_titik = res_data;

                // console.log(this.markers_titik);



              // 
    
    
            });
        },

        selectDataTitik: function(data) {


                this.titik = {
                    id: data.id,
                    subject_pajak_id: data.subject_pajak_id,
                    nik: data.nik,
                    nama: data.nama,
                    alamat: data.alamat,
                    kelurahan: data.kelurahan,
                    kecamatan: data.kecamatan,
                    rwrt: data.rwrt,
                    no_telp: data.no_telp,
                    lokasi: data.lokasi,
                    file: data.file,
                };

                this.file_old = data.file;

                console.log(this.titik);
          
        },


        
      
        simulateProgress(number) {
          // we set loading state
          this[`simpan${number}`] = true
          // simulate a delay
          setTimeout(() => {
            // we're done, we reset loading state
            this[`simpan${number}`] = false
          }, 3000)
        },
    
        cari_data: function() {
    
            // this.polygons = [];
          this.getView();
          this.getViewTitik();
          
        },
    
        
        Notify : function(message){
          this.$q.notify({
            progress: true,
            message: message,
            color: 'success',
            avatar: 'https://cdn.quasar.dev/img/boy-avatar.png',
            position : 'top',
            actions: [
              { label: 'Keluar', color: 'yellow', handler: () => { /* ... */ } }
            ],
            timeout: 10000,
          })
        },
    
        
    
        addLokasi: function () {
         this.lokasi.push({ lat: 0 , lng: 0});
        },
        hapusLokasi: function () {
         this.lokasi.splice(-1,1);
        }
    
      },
    
      computed: {
        google: gmapApi
      },
      mounted() {
    
        this.getView();
        this.getViewTitik();
    
      }
    }
    